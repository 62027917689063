import React from 'react';
import { Emoji } from '../emoji/emoji.component';
import { Row, Col } from 'react-simple-flex-grid';

import "react-simple-flex-grid/lib/main.css";

export class EmojiGrid extends React.Component {

  importAll(context) {
    let images = {};
    context.keys().forEach((item, index) => { images[item.replace('./', '')] = context(item); });
    return images;
  }

  render() {
    const images = this.importAll(require.context('../../public/emoji/thumbs', false, /\.(png|jpe?g|svg|gif)$/))

    return (
      <Row>
        {Object.keys(images).map((name) =>
          <Col key={name}
            xs={{ span: 12 }} sm={{ span: 6 }} md={{ span: 4 }}
            lg={{ span: 3 }} xl={{ span: 2 }}
          >
            <Emoji value={{name: name, image: images[name].default}} />
          </Col>
        )}
      </Row>
    )
  }
}
