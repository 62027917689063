import './home.component.css';
import React from 'react';
import { EmojiGrid } from '../emoji-grid/emoji-grid.component'

export class Home extends React.Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <h1>
          Emoji Fun Club
          </h1>
        </header>
        <EmojiGrid/>
      </div>
    );
  }

}
