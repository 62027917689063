import React from 'react'
import './emoji.component.css';

export class Emoji extends React.Component {
  render() {
    const emojiName = this.removeExtension(this.props.value.name);

    return (
      <div className="emoji">
        <img className="preview-icon" alt={emojiName} src={this.props.value.image} />
        <div className="name">{":" + emojiName + ":"}</div>
      </div>
    )
  }

  removeExtension(filename  ){
      var lastDotPosition = filename.lastIndexOf(".");
      if (lastDotPosition === -1) return filename;
      else return filename.substr(0, lastDotPosition);
  }
}
